import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { slide as Menu } from "react-burger-menu"
import logo from "../images/logo.png"
import back from "../images/back.png"

export default props => {
  
  const backLink = props.backLink ? props.backLink : "/";
//console.log(props.pageContext)
  return (
    <>
      <Helmet defaultTitle="Circus Ronaldo"  style={[{
        "cssText": `
          .bm-burger-button:after {
            content: "${props.taal.toUpperCase()}";
          }
        `
    }]} >
        <html lang={props.taal.toUpperCase()} />
        <title>Circus Ronaldo</title>
        <meta
          name="description"
          content="Welkom in de wereld van Circus Ronaldo."
        />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover,user-scalable=no"
        />        
      </Helmet>
      <div className="header">
        {props.back === "true" && (
          <Link to={backLink} className="back">
            <img src={back} alt="" />
          </Link>
        )}
        {props.back === "false" && <div className="menuSpacer" />}
        <Link to="/" className="logo">
          <img src={logo} alt="" />
        </Link>
        <div className="menuSpacer" />
      </div>
      {props.lang && <Menu right className="lang">
        <Link to="/">Nederlands</Link>
        <Link to="/fr">Français</Link>
        <Link to="/en">English</Link>
      </Menu>}
    </>
  )
}
